import React from "react";
import "./Introduction.css";
const Introduction = (props: any) => {
  return (
    <div className="section">
      <i className="introduction-header">Opposing Insights</i>
      <br />
      <div className="introduction-text">
        <br />
        <strong>Welcome!</strong>
        <p>
          Thank you for visiting my website and for your time and interest.
          <br />
          If there are any questions about the information please do get in
          touch.
          <br />
          <br />
          I define the visual DNA of brands, cities and organisations.
          <br />
          My observations, imaginative stories and discovery tours for companies
          and municipalities stimulate new thinking for innovative behaviour.
          <br />
          I share my eyes with international A-brand suppliers, companies in
          fashion, retail, financial services, real estate development and
          marketing communication.
          <br />
          My work is often described as eye-opening.
          <br />
          Municipalities invite me to visualise and describe the present status
          and the potential of their cities.
          <br />
          While taking people on my discovery tours throughout the world, I
          teach what daily insights can offer.
          <br />
          Inspiring trips offer the (re-) discovery of beauty and trace the
          challenges of innovation and new thinking.
          <br />
          Always with a catching curiosity for why people do what they do?
        </p>

        <p>
          The fundamentals of my approach are based on streetology.
          <br />
          There is no strategy without streetology.
          <br />
          <br />
          <strong>Understanding &nbsp;the difference between:</strong>
          <br />
          Looking and really seeing.
          <br />
          The difference between just hearing and really internalising what you
          hear.
          <br />
          Getting in touch and connect.
          <br />
          In order to create relevant, meaningful and challenging insights and
          perspectives for the coming years.
        </p>
        <p>
          <strong>Specialties:</strong>
          <br />
          DNA reseach for cities and organisations.
          <br />
          Discovery tours throughout the world.
          <br />
          Innovation and development.
          <br />
          Visual story telling.
        </p>
      </div>
    </div>
  );
};

export default Introduction;
